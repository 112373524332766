<template>
  <div>
    <div class="ratio ratio-21x9 animated-background">
    </div>
    <!-- 各個產品 -->
    <!-- API寫法 -->
    <div class="container">
      <div class="row pb-5 justify-content-center pt-4 pt-md-5">
        <div class="col-md-12 mb-md-3">
          <div class="row gx-4 gx-md-3 gy-5 mb-md-5">
            <div
              class="col-md-3 col-6 position-relative"
              v-for="(item, index) in 12"
              :key="index"
            >
              <div class="ratio ratio-1x1 animated-background py-6">
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
